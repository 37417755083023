<script setup lang="ts">
import { useColorMode } from '#imports'

const colorMode = useColorMode()
function toggleDark() {
  colorMode.preference = colorMode.preference === 'dark' ? 'light' : 'dark'
}
</script>

<template>
  <GButton
    btn="ghost-gray"
    square
    label="i-radix-icons-sun dark:i-radix-icons-moon"
    icon
    @click="toggleDark()"
  />
</template>
